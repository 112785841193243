import React, { Component } from 'react';
import Fetch from 'whatwg-fetch';
import BillyButton from './BillyButton';
const DSERVURL = (process.env.NODE_ENV !== 'production') ? 'http://localhost:3010' : 'https://www.splitthebilly.com/bserv';

/*  "dservhost": "http://localhost:3010",
    "prod": false
    "dservhost": "https://www.splitthebilly.com/bserv",
    "prod": true }*/

import LoginRegister from './LoginRegister';
import Trips from './Trips';
import GenericBase from './GenericBase';


let handleUserAuthenticationFailed = null;

function serverFetchContentType_HIDDEN(path, method, contentType, content, requireUserAuthentication) {
  if (!handleUserAuthenticationFailed) { console.error('Server fetch pre-condition fails: handleUserAuthenticationFailed undefined'); return; }
  
  var headers = {'Accept': 'application/json', 'Content-Type': contentType};
  
  if (requireUserAuthentication) {
    var accessToken = GenericBase.getFromLocalStorage('stby-access-token');
    if (accessToken) path = path + '/' + accessToken;
  }
  
  let request = { method: method, headers: headers };
  if (content) request['body'] = content;
  return fetch(DSERVURL + path, request);
}

function serverFetch(path, method, jsonContent, requireUserAuthentication) {
  var content = null;
  if (jsonContent) content = JSON.stringify(jsonContent);
  
  return serverFetchContentType_HIDDEN(path, method, 'application/json', content, requireUserAuthentication)
  .then(function(response) {
    // handle Authentication Required case
    if (response.status === 401) handleUserAuthenticationFailed(response);
    else if (response.status === 403) handleUserAuthenticationFailed(response);
    // parse JSON
    else return response.json();
  });
}


function dodoservFetchContentType(path, method, contentType, content) {
    var headers = {'Accept': 'application/json', 'Content-Type': contentType};
    
    try {
        var dodoUserid = localStorage.getItem('dodo-userid');
        // if (dodoUserid) path = path + '/' + dodoUserid;
    }
    catch(err) {
        console.log('Error using local storage');
    }
    
    let request = { method: method, headers: headers };
    if (content) request['body'] = content;
    return fetch(DSERVURL + path, request);
}

function dodoservFetch(path, method, jsonContent) {
    var content = null;
    if (jsonContent) content = JSON.stringify(jsonContent);
    return dodoservFetchContentType(path, method, 'application/json', content);
}


class App extends Component {
  
  constructor() {
    super();
    
    handleUserAuthenticationFailed = this.handleLogout;
      
    var initialLoggedIn = false;
	var initialName = null;
	var initialProcess = 'login';
		
		try {
			var accessToken = localStorage.getItem('stby-access-token');
			if (accessToken && accessToken.length > 0) {
				initialLoggedIn = true;
				initialProcess = 'main';
			}
			initialName = localStorage.getItem('stby-name');
		}
		catch(err) {
			console.log('Error using local storage');
		}
        
    this.state = {
      loggedIn: initialLoggedIn,
      name: initialName,
      process: initialProcess,
    };
    
    this.handleLogin = this.handleLogin.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.triggerProcess = this.triggerProcess.bind(this);
  }
  
  componentWillMount() {
        // retrieve query if specific in url
        var query = window.location.search.substr(1); // specified through ?somequery
        if (!query) query = window.location.pathname.split('/')[1]; // specified through /somequery
        if (query.toLowerCase().endsWith('-guide')) { // TODO Remove this
            query = query.substring(0, query.length-6); // remove '-guide' from query
        }
//        CommonD.queryFromUrl = query; // save query globally
  }
  
  componentDidMount() {
      // asynchronously load metadata from server
      serverFetch('/metadata', 'GET', null, false)
      .then(function(content) {
        GenericBase.metadata = content;
      })
      .catch(function(error) {
        console.error('Got an error: ' + error);
      });
      
      GenericBase.serverFetch = serverFetch;
  }

  handleLogin(email, password, callback) {
		var self = this;
		dodoservFetch('/authenticate', 'POST', {email:email, password:password})
		.then(function(response) {
			return response.json();
		})
		.then(function(content){
			console.log(content);
			if (content.error) throw 'Authentication failed'; // throw to catch clause
			
			try {
				localStorage.setItem('stby-access-token', content.accessToken);
				localStorage.setItem('stby-name', content.name);
			}
			catch(err) {
				console.error('Local storage error');
			}
            
            self.triggerProcess('main', function(state) {
              state.loggedIn = true;
              state.name = content.name;
            });
			callback(1);
		})
		.catch(function(error) {
			console.error('Got an error: ' + error);
			callback(0);
		});
  }
  
  handleLogout(response) {
    try {
      localStorage.removeItem('stby-access-token');
    }
    catch(err) {
      console.error('Local storage error');
    }
    
    this.triggerProcess('login', function(state){
      state.loggedIn = false;
    });
  }
  
  triggerProcess(process, funcState) {
    var state = Object.assign({}, this.state);
    state.process = process;
    if (funcState) funcState(state);
    this.setState(state);
  }
  
  
  render(){
    
    /*var logoStyle = {
      maxWidth: '320px',
      margin: '0 auto',
      padding: '0 0 0px 0',
      width: '100%' 
    };
    
    var style = {
      height: '100pt',
      color: '#000000',
      fontSize: '14pt',
      textAlign: 'left',
      paddingLeft: '7pt'
    };
    
    var iconStyle = { position: 'absolute', top: 0, left: 0, width: '18px' };
    */
    
    return (
      <div className='col-xs-12 col-md-10 col-md-offset-1' style={{marginBottom: 0, backgroundColor: '#eeeeee'}}>
        
        <div className='col-xs-12'>
          <span>Split the Billy</span>
        </div>
        
        <div className='col-xs-12' style={{textAlign:'right'}}>
          {this.state.loggedIn &&
          <span><span className='glyphicon glyphicon-user' /> {this.state.name} </span>
          }
          {this.state.loggedIn &&
          <BillyButton text='Logout' onClick={this.handleLogout.bind(this, null)} />
          }
        </div>
        
        {!this.state.loggedIn &&
		<LoginRegister onLogin={this.handleLogin} show={this.state.process === 'login'} triggerProcess={this.triggerProcess} />
		}
        
        <Trips loggedIn={this.state.loggedIn} show={true} fetch={serverFetch} />
      	
      </div>
    );
  }
}

export default App;