import React, { Component } from 'react';
import GenericBase from './GenericBase';
import BillyButton from './BillyButton';
import NameBadge from './NameBadge';


class SingleBill extends Component {
  
  constructor() {
    super();
    
    this.state = {
      unsavedChanges: false,
      invalidInput: null,
      excludeParticipants: [],
      payer: null,
      date: null,
      originalDate: null,
      isBusy: false,
    }
    
    this.handleChange = this.handleChange.bind(this);
    this.handleNameTap = this.handleNameTap.bind(this);
    this.handlePayerTap = this.handlePayerTap.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.setSpecialDate = this.setSpecialDate.bind(this);
  }
  
  componentDidMount() {
    var state = Object.assign({}, this.state);
    state.unsavedChanges = false;
    if (!this.props.bill.payer) state.unsavedChanges = true; // new items should ask for Save and Cancel
    state.invalidInput = null;
    state.payer = this.props.bill.payer;
    state.date = this.props.bill.date ? new Date(this.props.bill.date) : new Date();
    state.originalDate = new Date(state.date);
    state.isBusy = false;
    if (this.props.bill.excludeParticipants) state.excludeParticipants = this.props.bill.excludeParticipants.split(',').filter(function(x){return x!==''}).map(function(x){return Number(x)});
    else state.excludeParticipants = [];
    this.setState(state);
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (this.props.bill && prevProps.bill !== this.props.bill) {
      this.componentDidMount();
    }
  }
  
  identifyChanges() {
    var changed = {};
    if (!this.props.bill) return changed;
    
    // check for input element changes
    var keys = Object.keys(this.props.bill);
    for (var i = 0; i < keys.length; i++) {
      var current = document.getElementById('fld_' + keys[i]);
      if (!current) continue;
      current = current.value.trim();
      if (typeof this.props.bill[keys[i]] === 'number') current = Number(current);
      if (this.props.bill[keys[i]] !== current) changed[keys[i]] = current;
    }
    
    // check for payer change
    if (this.props.bill.payer !== this.state.payer) changed['payer'] = this.state.payer;
    
    // check for date change
    if (this.state.originalDate.getFullYear() !== this.state.date.getFullYear() || this.state.originalDate.getMonth() !== this.state.date.getMonth() || this.state.originalDate.getDate() !== this.state.date.getDate()) changed['date'] = this.state.date.getFullYear() + '-' + (1+this.state.date.getMonth()) + '-' + this.state.date.getDate();
    
    // check for name badge changes
    var currentExcludeParticipants;
    if (this.state.excludeParticipants.length === 0) currentExcludeParticipants = null;
    else currentExcludeParticipants = ',' + this.state.excludeParticipants.join(',') + ',';
    if (this.props.bill.excludeParticipants !== currentExcludeParticipants) changed['excludeParticipants'] = currentExcludeParticipants;
    
    return changed;
  }
  
  handleChange() {
    var changed = this.identifyChanges();
    var hasChanges = Object.keys(changed).length > 0;
    
    if (hasChanges !== this.state.unsavedChanges) {
      var state = Object.assign({}, this.state);
      state.unsavedChanges = hasChanges;
      this.setState(state);
    }
    
    // remove input validation error messages if possible
    if (this.state.invalidInput) this.inputIsInvalid();
  }
  
  handleNameTap(id) {
    // change configuration of name badges
    var state = Object.assign({}, this.state);
    if (state.excludeParticipants.indexOf(id) >= 0)   
      GenericBase.removeFromArray(state.excludeParticipants, id);
    // only set new state if at least one participat has been selected
    else if (state.excludeParticipants.length < this.props.participants.length - 1) state.excludeParticipants.push(id);
    this.setState(state, function() {
      this.handleChange();
    });
  }
  
  handlePayerTap(id) {
    var state = Object.assign({}, this.state);
    state.payer = id;
    this.setState(state, function() {
      this.handleChange();
    });
  }
  
  handleSave() {
    if (!this.props.onUpdateBill) return; // this should never happen
    if (this.inputIsInvalid()) return;
    
    var state = Object.assign({}, this.state);
    state.isBusy = true;
    var self = this;
    this.setState(state, function() {
      var changed = self.identifyChanges();
      if (Object.keys(changed).length === 0) return; // nothing to save, this should not happen
      
      self.props.onUpdateBill(self.props.bill.id, changed, function() {
        self.props.onBack(true);
      });
    }); 
  }
  
  inputIsInvalid() {
    var invalid = [];
    
    if (!this.state.payer) invalid.push('payer'); // payer must be set
    var amount = Number(document.getElementById('fld_amount').value.trim());
    if (isNaN(amount) || amount <= 0) invalid.push('fld_amount'); // amount must be positive number
    if (document.getElementById('fld_title').value.trim().length <= 0) invalid.push('fld_title'); // title must be set
    
    if (invalid.length === 0) invalid = null;
    
    if ((invalid) || (!invalid && this.state.invalidInput)) {
      var state = Object.assign({}, this.state);
      state.invalidInput = invalid;
      this.setState(state);
    }
    return invalid;
  }
  
  setSpecialDate(type) {
    var state = Object.assign({}, this.state);
    if (type === 0) state.date = new Date(); // today
    else if (type === 1) state.date.setDate(state.date.getDate() + 1);
    else if (type === 2) state.date.setDate(state.date.getDate() - 1);
    else if (type === 3) state.date.setMonth(state.date.getMonth() + 1);
    else if (type === 4) state.date.setMonth(state.date.getMonth() - 1);
    else if (type === 5) state.date.setYear(state.date.getFullYear() + 1);
    else if (type === 6) state.date.setYear(state.date.getFullYear() - 1);
    
    var self = this;
    this.setState(state, function() {
      self.handleChange();
    });
  }
  
  render(){
    
    if (this.state.hasError) {
      return (<div>Oops, there was an error</div>);
    }
    
    if (!this.props.bill) return null;
    
    // dropdown menu of all currencies based on metadata
    var currencyList = [(<div>No currencies loaded, please try again later</div>)];
    if (GenericBase.metadata.currency) {
      currencyList = GenericBase.metadata.currency.map(function(currency, index) {
        return (
          <option key={index} value={currency.id}>{currency.name} {currency.code}</option>
        );
      });
    }
    
    // list of participant checkboxes
    var participantsCheckboxes = null, payerCheckboxes = null;
    if (this.props.participants) {
      var self = this;
      participantsCheckboxes = this.props.participants.map(function(participant, index) {
        var excludeParticipant = (self.state.excludeParticipants.indexOf(participant.id) >= 0);
        return (
          <NameBadge key={index} name={participant.name} active={!excludeParticipant} onChange={self.handleNameTap} id={participant.id} />
        );
      });
      payerCheckboxes = this.props.participants.map(function(participant, index) {
        var isPayer = (participant.id === self.state.payer);
        return (
          <NameBadge key={index} name={participant.name} active={isPayer} onChange={self.handlePayerTap} id={participant.id} />
        );
      });
    }
    
    var payerName = null, paidFor = null;
    if (this.props.participants) {
      var self = this;
      payerName = this.props.participants.find(function(x) { return x.id === self.props.bill.payer; });
      if (payerName) payerName = payerName.name;
      var excluded = [];
      if (this.props.bill.excludeParticipants && this.props.bill.excludeParticipants !== '') 
        excluded = this.props.bill.excludeParticipants.split(',').map(function(x) { return parseInt(x); }).filter(function(x) { return (x); });
      
      if (excluded.length === 0) paidFor = 'everyone';
      else {
        paidFor = (this.props.participants.length - excluded.length);
        paidFor = this.props.participants.filter(function(x) { return excluded.indexOf(x.id) === -1; }).map(function(x) { return x.name; }).join(', ');
      }
    }
    
    
    // summary view for single bill, can be clicked to open editable view on separate screen
    if (!this.props.editableDetailView) {
      var style = GenericBase.styles.cardStyle;
      if (!this.props.onClick) style = Object.assign({}, style, {cursor: 'default'});
      
      return (
        <div className='col-xs-12' style={style} onClick={this.props.onClick}>
          <div style={{overflow:'auto'}}>
            
            <div className='col-xs-12 col-md-8' style={{float:'left'}}>
              
              <div style={{fontSize:'18pt'}}>{this.props.bill.title}</div>
              
              <div style={{fontSize:'14pt'}}>{payerName} paid for {paidFor}</div>
              
            </div>
            
            <div className='col-xs-12 col-md-4' style={{textAlign:'right'}}>
                
                <span style={{fontSize:'36pt'}}>{this.props.bill.amount}</span><span> </span>
                
                <span style={{fontSize:'18pt'}}>{GenericBase.currencyFor(this.props.bill.currency)}</span>
                
            </div>
            
          </div>
        </div>
      );
    }
    
    
    //var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    
    //var dateComponents = null;
    //if (this.props.bill.date) dateComponents = this.props.bill.date.split('-').map(function(x) { return Number(x); });
    //if (dateComponents.length !== 3) dateComponents = null;
    var dateFormatted = null;
    if (this.state.date) dateFormatted = this.state.date.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    
    
    // editable view for bill
    return (
      <div>
        
        {/* Navigation */}
        {(this.state.unsavedChanges) && 
        <BillyButton className='col-xs-3 col-md-2' icon='glyphicon-ok' text='Save' onClick={this.handleSave} busy={this.state.isBusy} />
        }
        {(this.state.unsavedChanges) && 
        <BillyButton className='col-xs-3 col-md-2' icon='glyphicon-remove' text='Cancel' onClick={this.props.onBack.bind(null, false)} />
        }
        {(!this.state.unsavedChanges) && 
        <BillyButton className='col-xs-3 col-md-2' icon='glyphicon-chevron-left' onClick={this.props.onBack.bind(null, false)} />
        }
        
        {/* Input field validation */}
        {(this.state.invalidInput) &&
        <div className='col-xs-12' style={GenericBase.styles.validationError}>Something's wrong, please check the input and try again</div>
        }
        
        <div className='col-xs-12' style={Object.assign({}, GenericBase.styles.cardStyle, {cursor: 'default'})}>
          <div style={{overflow:'auto'}}>
            <div className='col-xs-12 col-md-8' style={{float:'left'}}>
              
              <input style={GenericBase.styles.validate(this.state.invalidInput, 'fld_title', {fontSize:'14pt'})} type="text" className="form-control" placeholder="Title" defaultValue={this.props.bill.title} id="fld_title" onChange={this.handleChange}></input>
              
              <div className='col-xs-12' style={GenericBase.styles.validate(this.state.invalidInput, 'payer', {})}>
                <span className='col-xs-12'>{payerCheckboxes}</span>
                <span className='col-xs-12' style={{fontSize: '12pt', marginLeft: '10px', marginRight: '10px'}}>paid for</span>
                <span className='col-xs-12'>{participantsCheckboxes}</span>
              </div>
              
            </div>
            
            <div className='col-xs-12 col-md-4' style={{textAlign:'right'}}>
              
              <input style={GenericBase.styles.validate(this.state.invalidInput, 'fld_amount', {fontSize:'14pt'})} type="text" className="form-control" placeholder="Amount" defaultValue={this.props.bill.amount} id="fld_amount" onChange={this.handleChange}></input>
              
              <select className="form-control" defaultValue={this.props.bill.currency} id="fld_currency" onChange={this.handleChange}>
                {currencyList}
              </select>
              
            </div>
            
            
            <div className='col-xs-12'>
              <div className='col-xs-12' style={{padding: 0, fontSize: '16pt'}}>
                {dateFormatted}
              </div>
              
              <div className='col-xs-12' style={{margin: 0, padding: '10px 0 10px 0'}}>
                <BillyButton icon='glyphicon-plus' onClick={this.setSpecialDate.bind(null, 1)} />
                <BillyButton icon='glyphicon-minus' text='Day' onClick={this.setSpecialDate.bind(null, 2)} style={{marginRight: '12px'}} />
                <BillyButton icon='glyphicon-plus' onClick={this.setSpecialDate.bind(null, 3)} />
                <BillyButton icon='glyphicon-minus' text='Month' onClick={this.setSpecialDate.bind(null, 4)} style={{marginRight: '12px'}} />
                <BillyButton icon='glyphicon-plus' onClick={this.setSpecialDate.bind(null, 5)} />
                <BillyButton icon='glyphicon-minus' text='Year' onClick={this.setSpecialDate.bind(null, 6)} style={{marginRight: '12px'}} />
                <BillyButton icon='glyphicon-time' text='Today' onClick={this.setSpecialDate.bind(null, 0)} />
              </div>
            </div>
            
          </div>
        </div>
    
    </div>
    );
  }
}

export default SingleBill;