import React, { Component } from 'react';
import GenericBase from './GenericBase';
import OneTrip from './OneTrip';
import Bills from './Bills';
import BillyButton from './BillyButton';


class Trips extends Component {
  constructor() {
    super();
    
    this.state = {
      trips: null,
      tripFromLink: null,
      selectedTripIndex: -1
    };
    
    this.loadTrips = this.loadTrips.bind(this);
    this.handleSelectTrip = this.handleSelectTrip.bind(this);
    this.handleUpdateSelectedTrip = this.handleUpdateSelectedTrip.bind(this);
    this.handleInsertTrip = this.handleInsertTrip.bind(this);
  }
  
  componentDidMount() {
    var paths = window.location.pathname.split('/');
    if (paths && paths.length > 1) {
      var tripTokenFromLink = paths[1];
      if (tripTokenFromLink) this.loadTripFromLink(tripTokenFromLink);
    }
    
    // if already logged in from the very beginning, load user's trips
    if (this.props.loggedIn) {
      this.loadTrips(null);
    }
  }
  
  componentDidUpdate(prevProps, prevState){
    // if now logged in, retriefe all trips
    if (this.props.loggedIn && !prevProps.loggedIn) {
      this.loadTrips(null);
    }
    
    // if now logged out, delete currently cached trips
    else if (!this.props.loggedIn && prevProps.loggedIn) {
      var state = Object.assign({}, this.state);
      state.trips = null;
      this.setState(state);
    }
  }
  
  loadTrips(stateFunction) {
    var self = this;
    this.props.fetch('/trips', 'GET', null, true)
    .then(function(content){
      var state = Object.assign({}, self.state);
      state.trips = content;
      if (stateFunction) stateFunction(state);
      self.setState(state);
    })
    .catch(function(error) {
      console.error('Got an error: ' + error);
    });
  }
  
  loadTripFromLink(tripToken) {
    // remove tripFromLink is called with argument null
    if (!tripToken) {
      if (this.state.tripFromLink) {
        var state = Object.assign({}, self.state);
        state.tripFromLink = null;
        self.setState(state);
      }
      return;
    }
    
    var self = this;
    this.props.fetch('/trip/' + tripToken, 'GET', null, false)
    .then(function(content){
      if (content && content.length > 0) {
        var state = Object.assign({}, self.state);
        state.tripFromLink = content[0];
        self.setState(state);
      }
    })
    .catch(function(error) {
      console.error('Got an error: ' + error);
    });
  }
  
  // if index >= 0, select trip, otherwise unselect current trip
  handleSelectTrip(index){
    var state = Object.assign({}, this.state);
    if (index < 0) { // negative index means unselect trip
      state.selectedTripIndex = -1;
      state.tripFromLink = null;
      if (window.location.pathname.length > 1) window.location.pathname = '/';
    }
    else {
      state.selectedTripIndex = index;
    }
    this.setState(state);
  }
  
  /*
  handleUpdateTrip(id, updateAttributes, successCallback) {
    console.log(updateAttributes);
    var self = this;
    var accessToken = GenericBase.getFromLocalStorage('stby-access-token');
    this.props.fetch('/trips/' + accessToken + '/' + id, 'PUT', updateAttributes, false)
    .then(function(content){
      if (content.success) {
        var updatedTrip = content.updated;
        var state = Object.assign({}, self.state);
        Object.assign(state.editTrip, updatedTrip); // update trip being displayed
        self.setState(state);
        
        if (successCallback) successCallback();
      }
    })
    .catch(function(error) {
      console.error('Got an error: ' + error);
    });
  }*/
  
  handleUpdateSelectedTrip(updatedTrip) {
    if (!updatedTrip) return;
    
    if (this.state.selectedTripIndex >= 0) {
      var state = Object.assign({}, this.state);
      // state.trips[this.state.selectedTripIndex] = updatedTrip;
      Object.assign(state.trips[this.state.selectedTripIndex], updatedTrip); // update trip being displayed
      this.setState(state);
    }
  }
  
  handleInsertTrip() {
    var self = this;
    var accessToken = GenericBase.getFromLocalStorage('stby-access-token');
    
    this.props.fetch('/trips/' + accessToken, 'POST', null, false)
    .then(function(content){
      if (content.success) {
        var insertId = content.inserted.id;
        self.loadTrips(function(state) {
          var insertedIndex = state.trips.findIndex(function(trip) { return trip.id === insertId });
          if (insertedIndex >= 0) state.selectedTripIndex = insertedIndex;
        });
      }
    })
    .catch(function(error) {
      console.error('Got an error: ' + error);
    });
  }
  
  render() {
    var loading = (<div>Loading trips</div>);
    if (!this.props.show) return null;
    if (!this.props.loggedIn) return null;
    if (!this.state.trips) return loading;
    
    // show trip from link
    if (this.state.tripFromLink) {
      return (
        <Bills trip={this.state.tripFromLink} tripReadOnly={true} fetch={this.props.fetch} onBack={this.handleSelectTrip.bind(null, -1)} onDidUpdateTrip={null} />
      )
    }
    
    // display bills of selected trip
    else if (this.state.selectedTripIndex >= 0) {
      return (
        <Bills trip={this.state.trips[this.state.selectedTripIndex]} fetch={this.props.fetch} onBack={this.handleSelectTrip.bind(null, -1)} onDidUpdateTrip={this.handleUpdateSelectedTrip} />
      )
    }
    
    var self = this;
    var list = this.state.trips.map(function(trip, index) {
      //if (!trip.name) return; // trip has been newly created, do not display in list of trips, but display as single trip to be edited
      return (<OneTrip key={index} trip={trip} summaryOnly={true} readOnly={false} onClick={self.handleSelectTrip.bind(null, index)} onDidUpdateTrip={null} fetch={null} />);
    });
    
    return (
      <div className='col-xs-12'>
        <BillyButton text='Create new trip' onClick={this.handleInsertTrip} />
        <BillyButton text='Refresh trips' onClick={this.loadTrips.bind(this, function() {})} />
        
        {list}
        
      </div>
    )
    
  }
}

export default Trips;