import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './style.css';
import App from "./js/components/App";

const wrapper = document.getElementById("main");
wrapper ? ReactDOM.render(<App />, wrapper) : false;


//import _ from 'lodash';
//import './style.css';
//
//function component() {
//  const element = document.createElement('div');
//
//  element.innerHTML = _.join(['Hello 8', 'webpack'], ' ');
//  element.classList.add('hello');
//  
//  return element;
//}
//
//document.body.appendChild(component());
