import React, { Component } from 'react';
import GenericBase from './GenericBase';


class CurrencyList extends Component {
  
  render(){
    // dropdown menu of all currencies based on metadata
    var currencyList = [(<div>No currencies loaded, try again later</div>)];
    if (GenericBase.metadata.currency) {
      currencyList = GenericBase.metadata.currency.map(function(currency, index) {
        return (
          <option key={index} value={currency.id}>{currency.name} {currency.code}</option>
        );
      });
    }
    
    return (
      <select className="form-control" defaultValue={this.props.value} id={this.props.id} onChange={this.props.onChange}>
        {currencyList}
      </select>
    );
  }
}

export default CurrencyList;