import React, { Component } from 'react';
import GenericBase from './GenericBase';
import NameBadge from './NameBadge';
import CurrencyList from './CurrencyList';
import BillyButton from './BillyButton';


class OneTrip extends Component {
  constructor() {
    super();
    
    this.state = {
      unsavedChanges: false,
      invalidInput: null,
      showDetails: false,
      editDetails: false,
      addedParticipantsPending: [],
      modifiedParticipantsPending: [],
      removedParticipantsPending: [],
      modifiedTitlePending: false,
      modifiedHomeCurrencyPending: false,
    };
    
    this.handleShowEditDetails = this.handleShowEditDetails.bind(this);
    this.handleEditElement = this.handleEditElement.bind(this);
    this.handleAddParticipant = this.handleAddParticipant.bind(this);
    this.handleRemoveParticipant = this.handleRemoveParticipant.bind(this);
    this.handleEditParticipant = this.handleEditParticipant.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSaveCancel = this.handleSaveCancel.bind(this);
  }
  
  componentDidMount() {
    var state = Object.assign({}, this.state);
    state.unsavedChanges = false;
    if (!this.props.trip.name) state.unsavedChanges = true; // new items should ask for Save and Cancel
    state.showDetails = false;
    this.resetEditing(state);
    
    // if trip is edited for the first time, activate relevant input elements
    var editingNewlyInsertedTrip = (!this.props.trip.name);
    if (!this.props.summaryOnly && editingNewlyInsertedTrip) {
      state.showDetails = true;
      state.editDetails = true;
      state.modifiedTitlePending = true;
    }
    
    this.setState(state);
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (this.props.trip && prevProps.trip !== this.props.trip) {
      this.componentDidMount();
    }
  }
  
  handleShowEditDetails(showEditDetails) {
    var state = Object.assign({}, this.state);
    if (showEditDetails === 0) state.showDetails = false;
    else if (showEditDetails === 1) state.showDetails = true;
    else if (showEditDetails === 2) state.editDetails = true;
    
    if (!state.showDetails) {
      state.addedParticipantsPending = []; // ignore unsaved changes if details are being hidden
      state.editDetails = false; // can only edit details when showing details
    }
    this.setState(state);
  }
  
  handleEditElement(attribute) {
    if (this.state[attribute]) return; // ignore if already set
    
    var state = Object.assign({}, this.state);
    state[attribute] = true;
    this.setState(state);
  }
  
  handleAddParticipant() {
    var state = Object.assign({}, this.state);
    var countAdded = state.addedParticipantsPending.length;
    state.addedParticipantsPending.push('fld_addedParticipant_' + (1+countAdded));
    this.setState(state, function() {
      this.handleChange();
    });
  }
  
  handleRemoveParticipant(index) {
    var state = Object.assign({}, this.state);
    state.removedParticipantsPending.push(index);
    this.setState(state, function() {
      this.handleChange();
    });
  }
  
  handleEditParticipant(index) {
    var state = Object.assign({}, this.state);
    state.modifiedParticipantsPending.push(index);
    this.setState(state);
  }
  
  inputIsInvalid() {
    var invalid = [];
    
    var currentName = document.getElementById('fld_name');
    if (currentName) {
      currentName = currentName.value;
      if (!currentName || currentName.trim().length === 0) invalid.push('fld_name');
    }
    if (invalid.length === 0) invalid = null;
    
    if ((invalid) || (!invalid && this.state.invalidInput)) {
      var state = Object.assign({}, this.state);
      state.invalidInput = invalid;
      this.setState(state);
    }
    return invalid;
  }
  
  identifyChanges() {
    var changed = {};
    if (!this.props.trip) return changed; // no trip means no changes
    
    // check for name and home currency changes
    var keys = Object.keys(this.props.trip);
    for (var i = 0; i < keys.length; i++) {
      var current = document.getElementById('fld_' + keys[i]);
      if (!current) continue;
      current = current.value.trim();
      if (typeof this.props.trip[keys[i]] === 'number') current = Number(current);
      if (this.props.trip[keys[i]] !== current) changed[keys[i]] = current;
    }
    
    // check for participants change
    for (var i = 0; i < this.props.trip.participants.length; i++) {
      // check for deleted participant
      if (this.state.removedParticipantsPending.indexOf(i) >= 0) {
        if (!changed.participants) changed.participants = [];
        changed.participants.push({ action:'remove', id:this.props.trip.participants[i].id });
        continue;
      }
      
      // check for modified participant
      var current = document.getElementById('fld_participant' + i);
      if (!current) continue;
      current = current.value.trim();
      if (this.props.trip.participants[i].name !== current) {
        if (!changed.participants) changed.participants = [];
        changed.participants.push({ action:'update', id:this.props.trip.participants[i].id, name:current });
      }
    }
    
    // check for added participants
    if (this.state.addedParticipantsPending.length > 0) {
      for (var i = 0; i < this.state.addedParticipantsPending.length; i++) {
        var current = document.getElementById(this.state.addedParticipantsPending[i]);
        if (current) {
          current = current.value.trim();
          if (current.length > 0) {
            if (!changed.participants) changed.participants = [];
            changed.participants.push({ action:'add', name:current });
          }
        }
      }
    }
//    console.log(changed);
    return changed;
  }
  
  handleChange() {
    var changed = this.identifyChanges();
    var hasChanges = Object.keys(changed).length > 0;
    
    if (hasChanges !== this.state.unsavedChanges) {
      var state = Object.assign({}, this.state);
      state.unsavedChanges = hasChanges;
      this.setState(state);
    }
    
    // remove input validation error messages if possible by checking input validity once again
    if (this.state.invalidInput) this.inputIsInvalid();
  }
  
  resetEditing(state) {
    // reset all editing temporary variables
    state.invalidInput = null;
    state.editDetails = false;
    state.addedParticipantsPending = [];
    state.modifiedParticipantsPending = [];
    state.removedParticipantsPending = [];
    state.modifiedTitlePending = false;
    state.modifiedHomeCurrencyPending = false;
  }
  
  handleSaveCancel(save) {
    var self = this;
    
    // cancel all changes
    if (!save) {
      for (var i = 0; i < this.state.addedParticipantsPending.length; i++) {
        var field = document.getElementById(this.state.addedParticipantsPending[i]);
        if (field) field.value = null;
      }
      
      var state = Object.assign({}, this.state);
      this.resetEditing(state);
      this.setState(state, function() {
        self.handleChange();
      });
      return;
    }
    
    // save all changes
    if (this.inputIsInvalid()) return; // ignore if input is invalid, this should not happen
    
    var changes = this.identifyChanges();
    if (Object.keys(changes).length === 0) return; // nothing to save, this should not happen
    
    // update trip on server
//    console.log(changes);
    var accessToken = GenericBase.getFromLocalStorage('stby-access-token');
    this.props.fetch('/trips/' + accessToken + '/' + this.props.trip.id, 'PUT', changes, false)
    .then(function(content){
      if (content.success) {
        var updatedTrip = content.updated;
        
        var state = Object.assign({}, self.state);
        self.resetEditing(state);
        self.setState(state, function() {
          if (self.props.onDidUpdateTrip) self.props.onDidUpdateTrip(updatedTrip);
        });
      }
    })
    .catch(function(error) {
      console.error('Got an error: ' + error);
    });
  }
  
  participantName(id) {
    var participant = null;
    if (this.props.trip && this.props.trip.participants) {
      participant = this.props.trip.participants.find(function(p) { return p.id === id; });
    }
    
    if (participant) {
      if (participant.id === this.props.trip.youParticipant) return 'You';
      else return participant.name;
    }
    return null;
  }
  
  render() {
    var loading = (<div>Trip missing</div>);
    if (!this.props.trip) return loading;
    
    // newly inserted trip to be edited
    if (this.props.trip && this.props.summaryOnly && !this.props.trip.name) {
      return (
        <div className='col-xs-12' style={Object.assign({}, GenericBase.styles.cardStyle, {cursor: this.props.onClick ? 'pointer' : 'default', backgroundColor:'rgb(220,220,230)'})} onClick={this.props.onClick}>
          Empty trip &mdash; tap to edit
        </div>
      );
    }
    
    // list of every participant for the overview panel
    var self = this;
    var participantsList = null;
    var homeCurrency = GenericBase.currencyFor(this.props.trip.homeCurrency);
    if (this.props.trip) participantsList = this.props.trip.participants.map(function(x, index) { return <NameBadge name={x.name} active={true} claimed={x.userId} yourself={self.props.trip.youParticipant === x.id} key={index} />; });
    
    // table of every participant for the detailed panel
    var participantsTable = this.props.trip.participants.map(function(participant, index) {
      var beingModified = self.state.modifiedParticipantsPending.indexOf(index) >= 0; // index in modified participants list
      var beingRemoved = self.state.removedParticipantsPending.indexOf(index) >= 0; // index in removed participants list
      
      var participantBadge = beingModified
        ? (<span className='col-md-6'><input type="text" className="form-control" placeholder="Name" defaultValue={participant.name} id={'fld_participant' + index} onChange={self.handleChange}></input></span>)
        : (<NameBadge name={participant.name} active={!beingRemoved} claimed={participant.userId} yourself={self.props.trip.youParticipant === participant.id} />);
      
      var line = (<div className='col-xs-12' key={index}>
        <span className='col-md-6'>
          {participantBadge}
          {self.state.editDetails && !beingModified && !participant.userId && participant.paid === 0 && participant.spent === 0 &&
          <BillyButton icon='glyphicon-remove' onClick={self.handleRemoveParticipant.bind(self, index)} />
          }
          {self.state.editDetails && !beingModified && (!participant.userId || participant.id === self.props.trip.youParticipant) &&
          <BillyButton icon='glyphicon-pencil' onClick={self.handleEditParticipant.bind(self, index)} />
          }
        </span>
        <span className='col-md-3'>paid {participant.paid} {homeCurrency}</span>
        <span className='col-md-3'>and spent {participant.spent} {homeCurrency}</span>
      </div>);
      return line;
    });
    // include input field for new participant to be added
    if (this.state.addedParticipantsPending.length > 0) {
      for (var i = 0; i < this.state.addedParticipantsPending.length; i++) {
        participantsTable.push(
        <div className='col-xs-12' key={self.props.trip.participants.length + 1 + i}>
          <span className='col-md-6'><input type="text" className="form-control" placeholder="Name" id={this.state.addedParticipantsPending[i]} onChange={self.handleChange}></input></span>
        </div>
        );
      }
    }
    
    // overview on tally of every participant
    var yourTally = null, tallyActions = null;
    if (this.props.trip && this.props.trip.financials && this.props.trip.financials.actions) {
      var youParticipant = this.props.trip.youParticipant;
      yourTally = this.props.trip.financials.actions.reduce(function(tally, action) {
        if (action.from === youParticipant) return tally - action.amount;
        else if (action.to === youParticipant) return tally + action.amount;
        else return tally;
      }, 0);
      tallyActions = this.props.trip.financials.actions.map(function(action, index) {
        return <div key={index}>{self.participantName(action.from)} should pay {action.amount} {homeCurrency} to {self.participantName(action.to)}</div>
      });
    }
    
    var titleLine = 
      (<div style={{fontSize:'20pt'}}>
        {this.props.trip.name}
        {self.state.editDetails && !self.state.modifiedTitlePending &&
        <BillyButton icon='glyphicon-pencil' onClick={self.handleEditElement.bind(self, 'modifiedTitlePending')} />
        }
      </div>);
    if (this.state.modifiedTitlePending) titleLine = 
      (<div>
        <input type="text" className="form-control" placeholder="Name" defaultValue={this.props.trip.name} id="fld_name" onChange={this.handleChange} style={GenericBase.styles.validate(this.state.invalidInput, 'fld_name', {})}></input>
      </div>);
    
    var homeCurrencyLine = null;
    if (this.state.modifiedHomeCurrencyPending) homeCurrencyLine =
      (<CurrencyList id="fld_homeCurrency" value={this.props.trip.homeCurrency} onChange={this.handleChange} />);
    
    return (
      <div>
        <div className='col-xs-12' style={Object.assign({}, GenericBase.styles.cardStyle, {cursor: this.props.onClick ? 'pointer' : 'default', backgroundColor:'rgb(220,220,230)'})} onClick={this.props.onClick}>
          <div style={{overflow:'auto'}}>
            {/* Input field validation */}
            {(this.state.invalidInput) &&
            <div className='col-xs-12' style={GenericBase.styles.validationError}>Something's' wrong, please check the input and try again</div>
            }
            
            <div className='col-xs-12 col-md-6' style={{float:'left'}}>
              {titleLine}
              {!this.state.showDetails &&
              <div style={{}}>{participantsList}</div>
              }
            </div>
            
            <div className='col-xs-12 col-md-6' style={{textAlign:'right'}}>
              <div>
                Total <span style={{fontSize:'36pt'}}>{this.props.trip.financials ? this.props.trip.financials.totalSpend : '?'}</span> <span style={{fontSize:'18pt'}}>{self.state.modifiedHomeCurrencyPending ? null : GenericBase.currencyFor(this.props.trip.homeCurrency)}</span>
                {self.state.editDetails && !self.state.modifiedHomeCurrencyPending &&
                <BillyButton icon='glyphicon-pencil' onClick={self.handleEditElement.bind(self, 'modifiedHomeCurrencyPending')} />
                }
              </div>
              {homeCurrencyLine}
              <div>Your share is {yourTally ? ((yourTally > 0 ? '+' : '') + yourTally) : ('0')} {GenericBase.currencyFor(this.props.trip.homeCurrency)}</div>
            </div>
          
          </div>
          
          {!this.props.summaryOnly && !this.state.showDetails && 
          <BillyButton icon='glyphicon-chevron-down' text='Show details' onClick={this.handleShowEditDetails.bind(this, 1)} />
          }
        </div>
        
        {!this.props.summaryOnly && this.state.showDetails &&
        <div className='col-xs-12' style={Object.assign({}, GenericBase.styles.cardStyle, {cursor: 'default', backgroundColor:'rgb(220,220,230)'})}>
          
          {participantsTable}
          
          {this.state.editDetails &&
          <BillyButton className='col-xs-12' icon='glyphicon-plus' text='Add participant' onClick={this.handleAddParticipant} />
          }
          
          {this.state.showDetails ? <div>{tallyActions}</div> : null}
          
          {!this.props.summaryOnly && !this.state.editDetails &&
          <BillyButton icon='glyphicon-chevron-up' text='Hide this' onClick={this.handleShowEditDetails.bind(this, 0)} />
          }
          {!this.props.summaryOnly && !this.props.readOnly && !this.state.editDetails &&
          <BillyButton text='Edit details' onClick={this.handleShowEditDetails.bind(this, 2)} />
          }
          {!this.props.summaryOnly && this.state.editDetails && this.state.unsavedChanges &&
          <BillyButton icon='glyphicon-ok' text='Save' onClick={this.handleSaveCancel.bind(this, true)} />
          }
          {!this.props.summaryOnly && this.state.editDetails && (this.props.trip.name) &&
          <BillyButton icon='glyphicon-remove' text='Cancel editing' onClick={this.handleSaveCancel.bind(this, false)} />
          }
        </div>
        }
        
      </div>
    )
    
  }
}

export default OneTrip;