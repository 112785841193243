import React, { Component } from 'react';


class NameBadge extends Component {
  
  render(){
    var style = {
      backgroundColor: (this.props.active ? 'darkgray' : 'lightgray'),
      fontSize: '12pt',
      margin: '3px',
      textDecoration: (this.props.active ? 'none' : 'line-through'),
    };
    if (this.props.onChange) style.cursor = 'pointer';
    if (this.props.yourself) style.border = '1px solid rgb(122,122,122)';
    
    return (
      <span className="badge badge-light" onClick={this.props.onChange ? this.props.onChange.bind(null, this.props.id) : null} style={style}>
        {this.props.name + (this.props.claimed ? ' ' : '')}
        {this.props.claimed && 
        <span className='glyphicon glyphicon-user' />
        }
      </span>
    );
  }
}

export default NameBadge;