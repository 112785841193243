import React, { Component } from 'react';
import GenericBase from './GenericBase';


class BillyButton extends Component {
  
  constructor() {
    super();
    
    this.state = {};
  }
  
  render() {
    var iconComponent = null, textComponent = null;
    if (this.props.icon) iconComponent = (<span className={'glyphicon ' + (this.props.busy ? 'glyphicon-hourglass' : this.props.icon)} style={{marginLeft: '5px', marginRight: '5px'}} aria-hidden="true" />);
    if (this.props.text) textComponent = this.props.text;
    
    var buttonStyleOuter = GenericBase.styles.buttonStyleOuter;
    /*if (this.props.padding) {
      if (this.props.padding == 'left') buttonStyleOuter = Object.assign({}, buttonStyleOuter, {paddingLeft: '5px'});
      else if (this.props.padding == 'right') buttonStyleOuter = Object.assign({}, buttonStyleOuter, {paddingRight: '5px'});
      else if (this.props.padding == 'both') buttonStyleOuter = Object.assign({}, buttonStyleOuter, {paddingLeft: '5px', paddingRight: '5px'});
    }*/
    var buttonStyleInner = GenericBase.styles.buttonStyleInner;
    //if (!this.props.text || this.props.text.length === 0) buttonStyleInner = Object.assign({}, buttonStyleInner, {width: buttonStyleInner.minHeight});
    if (this.props.busy) buttonStyleInner = Object.assign({}, buttonStyleInner, {backgroundColor: 'rgb(230,230,230)', pointer: 'default'});
    buttonStyleOuter = Object.assign({}, buttonStyleOuter, this.props.style);
    
    return (
      <span className={this.props.className} style={buttonStyleOuter}>
        <span style={buttonStyleInner} onClick={this.props.busy ? null : this.props.onClick}>
          {iconComponent} {textComponent}
        </span>
      </span>
    );
  }
}

export default BillyButton;