// This replaces GenericBase

class GenericBase {
  
  static removeFromArray(array, element) {
    var index = array.indexOf(element);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }
  
  static getFromLocalStorage(key) {
    var value = null;
    try {
      value = localStorage.getItem(key);
    }
    catch(err) {
    }
    return value;
  }
  
  static validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  
  static currencyFor(id) {
    if (!this.metadata || !this.metadata.currency) return '';
    
    var found = this.metadata.currency.find(function(x) { return x.id === id; });
    if (!found) return '';
    
    return found.code;
  }
  
  static debugObject(o) {
    var out = '';
    for (var p in o) {
        out += p + ': ' + o[p] + '\n';
    }
    console.log(out);
  }
  
  static sleep(milliseconds) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if ((new Date().getTime() - start) > milliseconds){
        break;
      }
    }
  }
};

GenericBase.metadata = null;
GenericBase.serverFetch = null;

GenericBase.utilities = class utilities {
  static isString(s) {
    return typeof(s) === 'string' || s instanceof String;
  }
  
  static printError(err) {
    console.error('Error:', err);
  }
  
  static printErrorStacktrace(err) {
    console.error('--- ERROR --------------');
    if (err.hasOwnProperty('stack')) console.error(err.stack);
    else {
      console.error(err);
      console.trace();
    }
    console.error('------------------------');
  }
};

GenericBase.promises = class promises {
  // Source: https://stackoverflow.com/questions/29880715/how-to-synchronize-a-sequence-of-promises
  static processSequentially(items, processItemReturningPromise) {

    return new Promise(function(resolve, reject) {

      var index = 0;

      function next() {
          if (index < items.length) {
              processItemReturningPromise(items[index++])
              .then(next)
              .catch(function(error) {
                reject(error);
              });
          }
          else { // after last execution, run cleanup task
            resolve();
          }
      }
      next();

    })
  }
};

GenericBase.pythonBridge = class pythonBridge {
  // if not used, set pythonArgument=[] and relayPaylod=null
  static executeForBuffer(pythonCommand, pythonScript, pythonArguments, relayPayload) {
    const scriptArgs = [pythonScript].concat(pythonArguments);
    return new Promise(function(success, nosuccess) {
      let chunks = [];
      const { spawn } = require('child_process');
      const pyprog = spawn(pythonCommand, scriptArgs);

      pyprog.stdout.on('data', function(buffer) {
        chunks.push(buffer);
      });
      pyprog.stdout.on('close', function() {
        const totalBuffer = Buffer.concat(chunks);
        success([totalBuffer, relayPayload]); // note that relayPayload may be null
      });
      pyprog.stderr.on('data', function(rawError) {
        var error = rawError instanceof Buffer ? rawError.toString() : rawError;
        nosuccess(error);
      });
    });
  }

  static executeForString(pythonCommand, pythonScript, pythonArguments, relayPayload) {
    return this.executeForBuffer(pythonCommand, pythonScript, pythonArguments, relayPayload)
    .then(function([buffer, relayPayload]) {
      const stringContent = buffer.toString();
      return Promise.resolve([stringContent, relayPayload]);
    });
  }

  static executeForJSON(pythonCommand, pythonScript, pythonArguments, relayPayload) {
    return this.executeForBuffer(pythonCommand, pythonScript, pythonArguments, relayPayload)
    .then(function([buffer, relayPayload]) {
      const jsonContent = JSON.parse(buffer);
      return Promise.resolve([jsonContent, relayPayload]);
    });
  }
}

GenericBase.styles = class styles {
  static validate(invalidInput, field, styles) {
    if (invalidInput && invalidInput.indexOf(field) >= 0) {
      return Object.assign({}, styles, {borderLeft: '10px solid rgb(244, 191, 66)'});
    }
    return styles;
  }
};

GenericBase.styles.cardStyle = {
  minHeight: '100px',
  backgroundColor: 'rgb(255, 255, 255)',
  borderColor: 'rgb(200, 200, 200)',
  borderWidth: '0.8px',
  borderStyle: 'solid',
  marginTop: '5px',
  marginBottom: '5px',
  padding: '10px',
  cursor: 'pointer',
  boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19)',
};

GenericBase.styles.buttonStyleOuter = {
  margin: 0,
  marginTop: '5px',
  marginBottom: '5px',
  padding: 0,
  //backgroundColor: 'blue',
};
    
GenericBase.styles.buttonStyleInner = {
  padding: '9px 9px 9px 9px', // top, right, bottom, left
  minWidth: '40px',
  minHeight: '40px',
  backgroundColor: 'rgb(210, 244, 229)',
  color: 'rgb(112, 165, 142)',
  borderColor: 'rgb(112, 165, 142)',
  borderWidth: '0.8px',
  borderStyle: 'solid',
  cursor: 'pointer',
  //boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19)',
  textTransform: 'uppercase',
  fontSize: '11pt',
  textAlign: 'center',
  borderRadius: '40px',
  display: 'inline-block',
};

GenericBase.styles.validationError = {
  borderLeft: '10px solid rgb(244, 191, 66)',
};

export default GenericBase;