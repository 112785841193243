import React, { Component } from 'react';
import GenericBase from './GenericBase';
import BillyButton from './BillyButton';


class LoginRegister extends Component {
  constructor() {
    super();
    
    this.state = {
      process: 'login',
      error: null,
      errorVisible: false
    };
    
    this.triggerProcess = this.triggerProcess.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
  }
  
  triggerProcess(process) {
    var state = Object.assign({}, this.state);
    state.process = process;
    this.setState(state);
  }
  
  handleLogin(event){
    var self = this;
	event.preventDefault();
	
	var email = document.getElementById('emailField').value;
	var password = document.getElementById('passwordField').value;
    
	this.props.onLogin(email, password, function(authenticationResult) {
	 if (!authenticationResult) {
	   var state = self.state;
       state.errorVisible = true;
       self.setState(state);
     }
    });
  }
  
  setError(error) {
    var state = Object.assign({}, this.state);
    state.error = error;
    this.setState(state);
  }
  
  handleRegister(event){
    var self = this;
	event.preventDefault();
	
	var name = document.getElementById('nameField').value.trim();
	var email = document.getElementById('emailField').value.trim();
	var password = document.getElementById('passwordField').value.trim();
	var passwordRepeat = document.getElementById('passwordRepeatField').value.trim();
    
    if (!email || email.length === 0) this.setError('Please enter e-mail address');
    else if (!GenericBase.validateEmail(email)) this.setError('E-mail address is not valid');
    else if (!password || password.length === 0) this.setError('Please enter password');
    else if (password != passwordRepeat) this.setError('Repeat the same password');
    else {
      this.setError(null);
      
      var registrationObject = {email:email, password:password};
      if (name) registrationObject.name = name;
      
      var self = this;
      GenericBase.serverFetch('/user', 'POST', registrationObject, false)
      .then(function(content){
        if (content.errorid === GenericBase.metadata.errorCodes.userExists) {
          self.setError('A user with this e-mail address already exists, please log in');
        }
        else if (!content.authenticated) throw 'Authentication failed'; // throw to catch clause
        else self.triggerLogin(content); // store accessToken and name locally and trigger login view
      })
      .catch(function(error) {
        console.error('Got an error: ' + error);
      });
    }
  }
  
  triggerLogin(content) {
    try {
      localStorage.setItem('stby-access-token', content.accessToken);
      localStorage.setItem('stby-name', content.name);
    }
	catch(err) {
      console.error('Local storage error');
    }
    
    this.props.triggerProcess('main', function(state) {
      state.loggedIn = true;
      state.name = content.name;
    });
  }
  
  render() {
    if (!this.props.show) return null;
    
    if (this.state.process === 'register') return (
      <div className='col-xs-12 col-md-6 col-md-offset-3' style={GenericBase.styles.cardStyle}>
        <form id="loginform" className="form-horizontal" onSubmit={this.handleRegister}>
        <div className="input-group">
          <div className='col-xs-12'>
            <input type="text" className="form-control" id="nameField" placeholder="Your name"></input>
          </div>
          <div className='col-xs-12'>
            <input type="text" className="form-control" id="emailField" placeholder="Your e-mail address"></input>
          </div>
          <div className='col-xs-12'>
            <input type="password" className="form-control" id="passwordField" placeholder="Your password"></input>
          </div>
          <div className='col-xs-12'>
            <input type="password" className="form-control" id="passwordRepeatField" placeholder="Repeat your password"></input>
          </div>
          <div className='col-xs-12'>
            <div className="input-group-btn">
              <BillyButton text='Register new account' onClick={this.handleRegister} />
            </div>
          </div>
          
          {(this.state.error) &&
            <div>{this.state.error}</div>
		  }
        </div>
        
        <div className='col-xs-12'>
          <span>Already have an account? </span>
          <BillyButton text='Log in' onClick={this.triggerProcess.bind(this, 'login')} />
        </div>
        
        </form>
      </div>
    );
    
	return (
      <div className='col-xs-12 col-md-6 col-md-offset-3' style={GenericBase.styles.cardStyle}>
        <form id="loginform" className="form-horizontal" onSubmit={this.handleLogin}>
        <div className="input-group">
          <div className='col-xs-12'>
            <input type="text" className="form-control" id="emailField" placeholder="Your e-mail address" value={this.state.emailAddress}></input>
          </div>
          <div className='col-xs-12'>
            <input type="password" className="form-control" id="passwordField" placeholder="Your password" value={this.state.password}></input>
          </div>
          <div className='col-xs-12'>
            <div className="input-group-btn">
              <BillyButton text='Log in' onClick={this.handleLogin} />
            </div>
          </div>
          
          {(this.state.errorVisible) &&
            <div>Please try again</div>
		  }
        </div>
        
        <div className='col-xs-12'>
          <span>Don't have an accout yet? </span>
          <BillyButton text='Register new' onClick={this.triggerProcess.bind(this, 'register')} />
        </div>
        
        </form>
      </div>
    );
  }
}

export default LoginRegister;