import React, { Component } from 'react';
import GenericBase from './GenericBase';


class ClaimParticipant extends Component {
  
  constructor() {
    super();
    
    this.state = {
      claimParticipant: null
    }
    
    this.handleClaimParticipant = this.handleClaimParticipant.bind(this);
    this.handleConfirmClaim = this.handleConfirmClaim.bind(this);
  }
  
  handleClaimParticipant(participant) {
    var state = Object.assign({}, this.state);
    state.claimParticipant = participant;
    this.setState(state);
  }
  
  handleConfirmClaim() {
    var self = this;
    var accessToken = GenericBase.getFromLocalStorage('stby-access-token');
    var name = document.getElementById('fld_name').value.trim();
    var body = (name && name.length > 0) ? {name:name} : null;
    this.props.fetch('/claim/' + accessToken + '/' + this.props.trip.id + '/' + this.state.claimParticipant.id, 'PUT', body, false)
    .then(function(content){
      if (content.success) {
/*        var state = Object.assign({}, self.state);
        Object.assign(state.selectedBill, updatedBill); // go back to main view
        self.setState(state);*/
        console.log('Claim successful: ' + content);
        self.props.onBack();
      }
    })
    .catch(function(error) {
      console.error('Got an error: ' + error);
    });
  }
  
  render() {
    
    if (!this.props.trip) return null;
    
    if (this.state.claimParticipant) {
      return (
      <div className='col-xs-12' style={Object.assign({}, GenericBase.styles.cardStyle, {cursor: 'default',   backgroundColor: 'rgb(255, 190, 190)'})}>
        <input type="text" className="form-control" placeholder="Your name" defaultValue={this.state.claimParticipant.name} id="fld_name"></input>
        <button type="button" className="btn btn-default" onClick={this.handleConfirmClaim}>Claim user</button>
        <button type="button" className="btn btn-default" onClick={this.handleClaimParticipant.bind(this, null)}>Cancel</button>
      </div>
      )
    }
    
    var participantsToClaim = participantsToClaim = this.props.trip.participants.filter(function(p) {
      return (!p.userId);
    });
    var self = this;
    participantsToClaim = participantsToClaim.map(function(p, index) {
      return <li><div key={index}>{p.name} <a onClick={self.handleClaimParticipant.bind(self, p)}>This is me</a></div></li>;
    });
    
    return (
      <div className='col-xs-12' style={Object.assign({}, GenericBase.styles.cardStyle, {cursor: 'default',   backgroundColor: 'rgb(255, 190, 190)'})}>
        <div className="detail-text-paragraph" style={{fontSize:'14pt'}}>
          Have you been part of this trip? Claim your spot!
        </div>
        {(participantsToClaim && participantsToClaim.length > 0) &&
          <div className="detail-text-paragraph">
          {participantsToClaim.length === 1 ? 'Is this you?' : 'Which one are you?'}

          <ul>{participantsToClaim}</ul>

          </div>
        }
      </div>
    )
  }
}

export default ClaimParticipant;