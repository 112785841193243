import React, { Component } from 'react';
import GenericBase from './GenericBase';
import SingleBill from './SingleBill';
import OneTrip from './OneTrip';
import NameBadge from './NameBadge';
import BillyButton from './BillyButton';
import ClaimParticipant from './ClaimParticipant';


class Bills extends Component {
  
  constructor() {
    super();
    
    this.state = {
      bills: null,
      selectedBill: null,
    }
    
    this.handleSelectBill = this.handleSelectBill.bind(this);
    this.handleBackFromSingleBill = this.handleBackFromSingleBill.bind(this);
    this.handleUpdateBill = this.handleUpdateBill.bind(this);
    this.handleInsertBill = this.handleInsertBill.bind(this);
  }
  
  componentDidMount() {
    // if already logged in from the very beginning, load user's trips
    if (this.props.trip) {
      this.loadBills(null);
    }
  }
  
  componentDidUpdate(prevProps, prevState){
    // if new trip has been set, get all bills of this trip
    if (this.props.trip && prevProps.trip !== this.props.trip) {
      this.loadBills(null);
    }
    
    // trip not given, remove bills from state
    else if (!this.props.trip && this.state.bills)  {
      var state = Object.assign({}, this.state);
      state.bills = null;
      state.selectedBill = null;
      this.setState(state);
    }
  }
  
  refreshTrip() {
    var self = this;
    this.props.fetch('/trip/' + this.props.trip.tripToken, 'GET', null, false)
    .then(function(content){
      // console.log(content);
      var refreshedTrip = (content.length > 0 && !content.error) ? content[0] : null; 
      // override trip with updated attributes
      if (self.props.trip && refreshedTrip && self.props.onDidUpdateTrip) self.props.onDidUpdateTrip(refreshedTrip);
    })
    .catch(function(error) {
      console.error('Got an error: ' + error);
    });
  }
  
  loadBills(stateFunction) {
    var self = this;
    this.props.fetch('/bills/' + this.props.trip.tripToken, 'GET', null, false)
    .then(function(content){
      var state = Object.assign({}, self.state);
      state.bills = content;
      if (stateFunction) stateFunction(state);
      self.setState(state);
    })
    .catch(function(error) {
      console.error('Got an error: ' + error);
    });
  }
  
  // if index >= 0, select bill, otherwise unselect current bill
  handleSelectBill(index){
    var state = Object.assign({}, this.state);
    if (index < 0) state.selectedBill = null; // negative index means unselect bill
    else state.selectedBill = state.bills[index];
    this.setState(state);
  }
  
  handleBackFromSingleBill(refreshTrip) {
    // refresh trip if changes in bill require new calculation of trip summary
    if (refreshTrip) this.refreshTrip();
    this.handleSelectBill(-1); // unselect bill
  }
  
  handleUpdateBill(id, updateAttributes, successCallback) {
    var self = this;
    var accessToken = GenericBase.getFromLocalStorage('stby-access-token');
    this.props.fetch('/bills/' + accessToken + '/' + id, 'PUT', updateAttributes, false)
    .then(function(content){
      if (content.success) {
        var updatedBill = content.updated;
        var state = Object.assign({}, self.state);
        Object.assign(state.selectedBill, updatedBill); // update bill being displayed
        self.setState(state, function() {
          if (successCallback) successCallback();
        });
      }
    })
    .catch(function(error) {
      console.error('Got an error: ' + error);
    });
  }
  
  handleInsertBill() {
    var self = this;
    var accessToken = GenericBase.getFromLocalStorage('stby-access-token');
    var newBill = {
      title: null,
      amount: 0
    };
    this.props.fetch('/bills/' + accessToken + '/' + this.props.trip.id, 'POST', newBill, false)
    .then(function(content){
      if (content && content.success) {
        var insertedId = content.inserted.id;
//        console.log('LOG inserted id is ' + insertedId);
        self.loadBills(function(state) {
          // LEGACY: if (state.bills.length > 0) state.selectedBill = state.bills[state.bills.length-1]; // select last Bill, which corresponds to newly inserted Bill
          // set newly inserted bill as selected bill
          var insertedBill = state.bills.find(function(x) { return x.id === insertedId; });
          // console.log('LOG inserted bill is ' + insertedBill);
          if (insertedBill) state.selectedBill = insertedBill;
        });
      }
      else {
        console.error('Insertion error', content);
      }
    })
    .catch(function(error) {
      console.error('Got an error: ' + error);
    });
  }
  
  render() {
    
    if (!this.props.trip) return null;
    
    // edit single bill
    if (this.state.selectedBill) {
      return (
        <SingleBill bill={this.state.selectedBill} editableDetailView={true} participants={this.props.trip.participants} onBack={this.handleBackFromSingleBill} onUpdateBill={this.handleUpdateBill} />
      )
    }
    
    var self = this;
    var listBills = <div>No bills yet</div>;
    var participantsId = this.props.trip.participants.map(function(x) { return x.id; });
    
    
    {/* Display each bill without allowing editing */}
    if (this.state.bills) listBills = this.state.bills.map(function(bill, index) {
      if (!bill.payer) return; // bill has newly been created, do not display in list if payer has not been set, but display as selectedBill
      
      return (<SingleBill key={index} bill={bill} editableDetailView={false} participants={self.props.trip.participants} onClick={self.props.tripReadOnly ? null : self.handleSelectBill.bind(self, index)} onBack={self.handleBackFromSingleBill} onUpdateBill={null} />);
    });
    
      /*console.log('----> ' + (listBills ? listBills.length : listBills) + ' ' + (list2 ? list2.length : list2));
    if (this.state.bills) listBills = listBills.concat(list2);
      console.log('----> then ' + (listBills ? listBills.length : listBills));
      console.log(listBills);*/
    
    
    return (
      <div>
        {this.props.tripReadOnly &&
          <ClaimParticipant trip={this.props.trip} onBack={this.props.onBack} fetch={this.props.fetch} />
        }
        
        <BillyButton icon='glyphicon-chevron-left' text={this.props.tripReadOnly ? 'Ignore' : null} onClick={this.props.onBack} />
        
        {(!this.props.tripReadOnly) && (this.props.trip.name) &&
        <BillyButton icon='glyphicon-plus' text='Insert new bill' onClick={this.handleInsertBill} />
        }
        
        <OneTrip trip={this.props.trip} summaryOnly={false} readOnly={this.props.tripReadOnly} onDidUpdateTrip={this.props.onDidUpdateTrip} fetch={this.props.fetch} />
        
        {listBills}
        
      </div>
    )
  }
}

export default Bills;